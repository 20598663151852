// @generated by protoc-gen-es v1.5.1 with parameter "target=ts"
// @generated from file autokitteh/events/v1/svc.proto (package autokitteh.events.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Event } from "./event_pb.js";

/**
 * @generated from message autokitteh.events.v1.SaveRequest
 */
export class SaveRequest extends Message<SaveRequest> {
  /**
   * @generated from field: autokitteh.events.v1.Event event = 1;
   */
  event?: Event;

  constructor(data?: PartialMessage<SaveRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.SaveRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: Event },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveRequest {
    return new SaveRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveRequest {
    return new SaveRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveRequest {
    return new SaveRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveRequest | PlainMessage<SaveRequest> | undefined, b: SaveRequest | PlainMessage<SaveRequest> | undefined): boolean {
    return proto3.util.equals(SaveRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.events.v1.SaveResponse
 */
export class SaveResponse extends Message<SaveResponse> {
  /**
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  constructor(data?: PartialMessage<SaveResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.SaveResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveResponse {
    return new SaveResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveResponse {
    return new SaveResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveResponse {
    return new SaveResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveResponse | PlainMessage<SaveResponse> | undefined, b: SaveResponse | PlainMessage<SaveResponse> | undefined): boolean {
    return proto3.util.equals(SaveResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.events.v1.GetRequest
 */
export class GetRequest extends Message<GetRequest> {
  /**
   * @generated from field: string event_id = 1;
   */
  eventId = "";

  /**
   * true: all values returned will be string values
   *       that contain the native values in JSON format.
   * false: all values returned are properly boxed.
   *
   * @generated from field: bool json_values = 2;
   */
  jsonValues = false;

  constructor(data?: PartialMessage<GetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.GetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "json_values", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetRequest {
    return new GetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetRequest {
    return new GetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetRequest | PlainMessage<GetRequest> | undefined, b: GetRequest | PlainMessage<GetRequest> | undefined): boolean {
    return proto3.util.equals(GetRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.events.v1.GetResponse
 */
export class GetResponse extends Message<GetResponse> {
  /**
   * @generated from field: autokitteh.events.v1.Event event = 1;
   */
  event?: Event;

  constructor(data?: PartialMessage<GetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.GetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: Event },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetResponse {
    return new GetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetResponse {
    return new GetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetResponse | PlainMessage<GetResponse> | undefined, b: GetResponse | PlainMessage<GetResponse> | undefined): boolean {
    return proto3.util.equals(GetResponse, a, b);
  }
}

/**
 * @generated from message autokitteh.events.v1.ListRequest
 */
export class ListRequest extends Message<ListRequest> {
  /**
   * @generated from field: string integration_id = 1;
   */
  integrationId = "";

  /**
   * @generated from field: string destination_id = 2;
   */
  destinationId = "";

  /**
   * @generated from field: string event_type = 3;
   */
  eventType = "";

  /**
   * @generated from field: uint32 max_results = 4;
   */
  maxResults = 0;

  /**
   * ASC or DESC values are supported
   *
   * @generated from field: string order = 5;
   */
  order = "";

  /**
   * @generated from field: string project_id = 7;
   */
  projectId = "";

  /**
   * @generated from field: string org_id = 8;
   */
  orgId = "";

  /**
   * see GetRequest.json_values.
   *
   * @generated from field: bool json_values = 6;
   */
  jsonValues = false;

  constructor(data?: PartialMessage<ListRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.ListRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "destination_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "event_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "max_results", kind: "scalar", T: 13 /* ScalarType.UINT32 */ },
    { no: 5, name: "order", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "project_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "json_values", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListRequest {
    return new ListRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListRequest {
    return new ListRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListRequest {
    return new ListRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListRequest | PlainMessage<ListRequest> | undefined, b: ListRequest | PlainMessage<ListRequest> | undefined): boolean {
    return proto3.util.equals(ListRequest, a, b);
  }
}

/**
 * @generated from message autokitteh.events.v1.ListResponse
 */
export class ListResponse extends Message<ListResponse> {
  /**
   * Events without their data.
   *
   * @generated from field: repeated autokitteh.events.v1.Event events = 1;
   */
  events: Event[] = [];

  constructor(data?: PartialMessage<ListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "autokitteh.events.v1.ListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "events", kind: "message", T: Event, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListResponse {
    return new ListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListResponse {
    return new ListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListResponse {
    return new ListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListResponse | PlainMessage<ListResponse> | undefined, b: ListResponse | PlainMessage<ListResponse> | undefined): boolean {
    return proto3.util.equals(ListResponse, a, b);
  }
}

