// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file autokitteh/orgs/v1/svc.proto (package autokitteh.orgs.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddMemberRequest, AddMemberResponse, CreateRequest, CreateResponse, DeleteRequest, DeleteResponse, GetMemberRequest, GetMemberResponse, GetOrgsForUserRequest, GetOrgsForUserResponse, GetRequest, GetResponse, ListMembersRequest, ListMembersResponse, RemoveMemberRequest, RemoveMemberResponse, UpdateMemberRequest, UpdateMemberResponse, UpdateRequest, UpdateResponse } from "./svc_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service autokitteh.orgs.v1.OrgsService
 */
export const OrgsService = {
  typeName: "autokitteh.orgs.v1.OrgsService",
  methods: {
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.Create
     */
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.Get
     */
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.Update
     */
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.Delete
     */
    delete: {
      name: "Delete",
      I: DeleteRequest,
      O: DeleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.AddMember
     */
    addMember: {
      name: "AddMember",
      I: AddMemberRequest,
      O: AddMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.UpdateMember
     */
    updateMember: {
      name: "UpdateMember",
      I: UpdateMemberRequest,
      O: UpdateMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.RemoveMember
     */
    removeMember: {
      name: "RemoveMember",
      I: RemoveMemberRequest,
      O: RemoveMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.ListMembers
     */
    listMembers: {
      name: "ListMembers",
      I: ListMembersRequest,
      O: ListMembersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.GetMember
     */
    getMember: {
      name: "GetMember",
      I: GetMemberRequest,
      O: GetMemberResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.orgs.v1.OrgsService.GetOrgsForUser
     */
    getOrgsForUser: {
      name: "GetOrgsForUser",
      I: GetOrgsForUserRequest,
      O: GetOrgsForUserResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

