// @generated by protoc-gen-connect-es v1.1.4 with parameter "target=ts"
// @generated from file autokitteh/users/v1/svc.proto (package autokitteh.users.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateRequest, CreateResponse, GetIDRequest, GetIDResponse, GetRequest, GetResponse, UpdateRequest, UpdateResponse } from "./svc_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service autokitteh.users.v1.UsersService
 */
export const UsersService = {
  typeName: "autokitteh.users.v1.UsersService",
  methods: {
    /**
     * @generated from rpc autokitteh.users.v1.UsersService.Create
     */
    create: {
      name: "Create",
      I: CreateRequest,
      O: CreateResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.users.v1.UsersService.Get
     */
    get: {
      name: "Get",
      I: GetRequest,
      O: GetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.users.v1.UsersService.GetID
     */
    getID: {
      name: "GetID",
      I: GetIDRequest,
      O: GetIDResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc autokitteh.users.v1.UsersService.Update
     */
    update: {
      name: "Update",
      I: UpdateRequest,
      O: UpdateResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

